import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/Chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTooth, faCamera, faTimes, faUpload, faCameraRetro } from "@fortawesome/free-solid-svg-icons";

const Chat = () => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([{ role: 'assistant', content: 'Hello! How can I help you today?' }]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [cameraStream, setCameraStream] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = async () => {
        setInput("");
        if (!input.trim()) return;
    
        const newMessage = { role: 'user', content: input };
        setMessages([...messages, newMessage]);
        setIsLoading(true);
    
        try {
            const response = await axios.post('https://dentalaid-1hub.onrender.com/ask', { question: input });
    
            let botResponse = response.data.answer;
    
            // Replace the specific URL with a clickable link
            const formattedResponse = botResponse.replace(
                "https://brushfloss.com",
                '<a href="https://brushfloss.com" target="_blank" rel="noopener noreferrer">https://brushfloss.com</a>'
            );
    
            setMessages([...messages, newMessage, { role: 'assistant', content: <span dangerouslySetInnerHTML={{ __html: formattedResponse }} /> }]);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        setIsLoading(true);
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        const imageUrl = URL.createObjectURL(file);
        setMessages([...messages, { role: 'user', content: <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%' }} /> }]);

        try {
            const response = await axios.post('https://dentalaid-1hub.onrender.com/analyze', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Replace the specific URL with a clickable link
            const formattedResponse = response.data.replace(
                "https://brushfloss.com",
                '<a href="https://brushfloss.com" target="_blank" rel="noopener noreferrer">https://brushfloss.com</a>'
            );

            setMessages([...messages, { role: 'user', content: <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%' }} /> }, { role: 'assistant', content: <span dangerouslySetInnerHTML={{ __html: formattedResponse }} /> }]);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsPopupOpen(false);
            setIsLoading(false);
        }
    };

    const openCamera = async () => {
        setIsPopupOpen(false);
        setIsCameraOpen(true);
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
            setCameraStream(stream);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const closeCamera = () => {
        if (cameraStream) {
            cameraStream.getTracks().forEach(track => track.stop());
        }
        setIsCameraOpen(false);
    };

    const takePicture = () => {
        const context = canvasRef.current.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        canvasRef.current.toBlob(async (blob) => {
            const file = new File([blob], 'captured.jpg', { type: 'image/jpeg' });
            handleImageUpload({ target: { files: [file] } });
        }, 'image/jpeg');
        closeCamera();
    };

    return (
        <div>
            <h1>DentalAId <FontAwesomeIcon icon={faTooth} /></h1>
            <div className="chatbox">
                <div className="chat-messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.role}`}>
                            <p>{msg.content}</p>
                        </div>
                    ))}
                    {isLoading && (
                        <div className="chat-message loading">
                            <p>DentalAId is typing</p>
                            <div className="dots">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="chat-input">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                    />
                    <button onClick={sendMessage}>Send</button>
                    <FontAwesomeIcon icon={faCamera} className="camera-icon" onClick={() => setIsPopupOpen(true)} />
                </div>
            </div>
            {isPopupOpen && (
                <div className="popup-backdrop">
                    <div className="popup">
                        <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={() => setIsPopupOpen(false)} />
                        <div className="popup-content">
                            <div className="popup-option" onClick={openCamera}>
                                <FontAwesomeIcon icon={faCameraRetro} size="3x" className="blue-icon" />
                                <p>Take a Picture</p>
                            </div>
                            <div className="popup-option">
                                <label htmlFor="upload-photo" style={{ cursor: 'pointer' }}>
                                    <FontAwesomeIcon icon={faUpload} size="3x" className="blue-icon" />
                                    <p>Upload a Photo</p>
                                </label>
                                <input type="file" id="upload-photo" onChange={handleImageUpload} style={{ display: 'none' }} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isCameraOpen && (
                <div className="camera-backdrop">
                    <div className="camera-popup">
                        <video ref={videoRef} autoPlay style={{ width: '100%' }} />
                        <button onClick={takePicture}>Capture</button>
                        <button onClick={closeCamera}>Close</button>
                        <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480"></canvas>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chat;
